* {
  @media screen {
    width: auto;
  }
}
.errmsg {
  background-color: lightpink;
  color: rgb(194, 50, 50);
  font-weight: bold;
  text-align: center;
}

.offscreen {
  position: absolute;
  left: -9999px;
}
.iconbtn {
  /* background-color: rgb(238, 170, 34); */
  background-color: #ffa645;
  border: none;
  color: black;
  padding: 12px 16px;
  font-size: 13px;
  cursor: pointer;
}
.confmsg {
  background-color: rgb(185, 255, 182);
  color: rgb(4, 43, 3);
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  text-align: center;
}
.tab ul > li > a {
  background-color: red;
}

.dashboard-card {
  min-height: 12rem;
  min-width: 300px;
  box-shadow: 0px 0px 16px #e2e2e2;
  padding: 8px;
  margin: 3px;
}
.dashboard-card-count {
  min-height: 6rem;
  box-shadow: 0px 0px 16px #e2e2e2;
  padding: 8px !important;
  margin: 3px;
  min-width: 400px;
  border-color: rgba(221, 113, 163, 0.014) !important;
}
.nav-pills > li > button.active {
  background-color: #738391 !important;
  font-weight: bolder !important;
}
.total {
  width: "50px";
  height: "50px";
  display: "flex";
  align-items: "center";
  justify-content: "center";
  object-fit: "cover";
  border-radius: "25px";
  background-color: "#f2eeed";
}
.dashboard-chart {
  box-shadow: 0px 0px 16px #ebdddd;
  margin: 3px;
}
.show > .dropdown-menu {
  display: block;
  position: absolute;
}
.color-me {
  color: rgba(17, 56, 230, 0.986) !important;
}
.count-div {
  display: "flex";
  align-items: "center";
  justify-content: "space-between";
}
.light-view {
  margin: "1px 16px ";
  min-width: "600px";
}
.table-view {
  max-width: 15%;
  padding: 10%;
}
.report-card {
  height: 35px;
  min-width: 100px;
  max-width: 150px;
  margin: 3px;
  border-radius: 10px;
  padding: 3px;
}
.report-select {
  margin: 3px;
  height: 35px;
  max-width: 150px;
  border-radius: 10px;
  padding: 2px;
}
.report-button {
  /* float: right; */
  margin: 2px;
}
.chart-container {
  display: flex;
  align-items: center;
}
.chart-text {
  text-align: center;
  width: 30px;
}
