*{
  box-sizing: border-box;
}

.parent {
  margin-left:-5px;
  margin-right:-5px;
  /* margin: 1rem; */
  padding: 2rem 2rem;
  text-align: left;
}
  
.child {
  float: left;
  width: 50%;
  padding: 5px;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}
.card{
  border:none
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  /* border: 1px solid #ddd; */
}

th, td {
  text-align: left;
  padding: 16px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
/* .parent { */
  /* border: 1px solid black; */
  /* margin: 1rem;
  padding: 1rem 1rem;
  text-align: center; */
/* }
.child { */
  /* display: inline-block; */
  /* border: 1px solid red; */
  /* padding: 5rem 5rem;
  vertical-align: middle; */
/* } */